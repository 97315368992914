define("ods-app/controllers/aplicacion/record/historial", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/aplicacion-historial"], function (_exports, _abstractModuleIndex, _aplicacionHistorial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelClass: _aplicacionHistorial.default,
    reload: true,
    includedRelationships: Ember.computed(function () {
      return Ember.A(['aplicacion']);
    }),
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'aplicacion'
        },
        value: this.get('record.id') || -1
      }];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Fecha',
        valuePath: 'createdAtFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Usuario',
        valuePath: 'createdBy',
        sortable: false,
        width: '100px'
      }, {
        label: 'Estatus',
        valuePath: 'estatusToString',
        sortable: false,
        width: '100px'
      }, // {
      //   label: 'Duración',
      //   valuePath: 'duracion',
      //   sortable: false,
      //   width: '100px',
      // },
      {
        label: 'Observaciones',
        valuePath: 'observaciones',
        sortable: false,
        width: '100px'
      }]);
    }),

    reloadModel() {
      this.set('reload', true);
    }

  });

  _exports.default = _default;
});