define("ods-app/routes/requerimiento/record/aplicacion/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-dependent-fields", "ember-changeset-validations/validators", "servir-ember-utilities/validators/custom-presence", "servir-ember-utilities/validators/is-codigo", "moment"], function (_exports, _abstractModuleRecord, _hasDependentFields, _validators, _customPresence, _isCodigo, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasDependentFields.default, {
    routeRecordIdentifierDynamicSegment: 'aplicacion_id',
    modelName: 'aplicacion',
    indexRoute: 'requerimiento.record.aplicacion',
    dependentRelatedAttributes: Ember.computed(function () {
      return [{
        dependentAttributePath: 'candidatoChangeset.departamentoNacimiento',
        dependentAttributeModelName: 'departamento',
        dependedUponAttributeName: 'paisNacimiento',
        dependentAttributeDependentPath: 'pais'
      }, {
        dependentAttributePath: 'candidatoChangeset.municipioNacimiento',
        dependentAttributeModelName: 'municipio',
        dependedUponAttributeName: 'candidatoChangeset.departamentoNacimiento',
        dependentAttributeDependentPath: 'departamento'
      }, {
        dependentAttributePath: 'candidatoChangeset.departamentoVecindad',
        dependentAttributeModelName: 'departamento',
        dependedUponAttributeName: 'paisVecindad',
        dependentAttributeDependentPath: 'pais'
      }, {
        dependentAttributePath: 'candidatoChangeset.municipioVecindad',
        dependentAttributeModelName: 'municipio',
        dependedUponAttributeName: 'candidatoChangeset.departamentoVecindad',
        dependentAttributeDependentPath: 'departamento'
      }, {
        dependentAttributePath: 'candidatoChangeset.subarea',
        dependentAttributeModelName: 'subarea',
        dependedUponAttributeName: 'area',
        dependentAttributeDependentPath: 'area'
      }];
    }),
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        candidato: (0, _validators.validatePresence)({
          presence: true
        }),
        requerimiento: (0, _validators.validatePresence)({
          presence: true
        }),
        createdAt: (0, _validators.validatePresence)({
          presence: true
        }),
        expectativaSalarial: [(0, _validators.validatePresence)({
          presence: true
        }), (0, _validators.validateNumber)()],
        fuenteReclutamiento: (0, _validators.validatePresence)({
          presence: true
        }),
        estatus: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),
    relatedModelsToQuery: Ember.computed(function () {
      return [{
        name: 'paises',
        modelName: 'pais'
      }, {
        name: 'areas',
        modelName: 'area'
      }, {
        name: 'fuentesReclutamiento',
        modelName: 'fuente-reclutamiento',
        queryParams: {
          filter: {
            tipo: this.constants.REQUIREMENT_SOURCE_TYPE_CANDIDATO
          }
        }
      }, {
        name: 'modeloConfiguracionCandidato',
        modelName: 'modelo-configuracion',
        queryParams: {
          filter: {
            nombreModelo: {
              exact: 'candidato'
            }
          }
        }
      }];
    }),

    createRecordInstance() {
      const requerimiento = this.modelFor('requerimiento.record').record;
      const modelName = this.get('modelName');
      return this.store.createRecord(modelName, {
        requerimiento,
        estatus: this.constants.APPLICATION_STATUS.get('firstObject.key')
      });
    },

    setupController(controller) {
      // Es necesario setear este atributo antes de llamar al super() para que el
      // HasDependentFieldsMixin no setea los atributos del changeset que todavía
      // no existe.
      this.set('doNotSetDependentFieldsAtStart', true);

      this._super(...arguments);

      controller.set('currentDate', (0, _moment.default)().subtract(16, 'years'));
    },

    actions: {
      updatePaisNacimiento(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
        this.refreshOptionsForDependentOnSelects('paisNacimiento', selectedValue);
      },

      updateDepartamentoNacimiento(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
        this.refreshOptionsForDependentOnSelects('candidatoChangeset.departamentoNacimiento', selectedValue);
      },

      updateMunicipioNacimiento(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
      },

      updatePaisVecindad(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
        this.refreshOptionsForDependentOnSelects('paisVecindad', selectedValue);
      },

      updateDepartamentoVecindad(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
        this.refreshOptionsForDependentOnSelects('candidatoChangeset.departamentoVecindad', selectedValue);
      },

      updateMunicipioVecindad(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
      },

      onAreaUpdated() {
        this.refreshOptionsForDependentOnSelects('area', this.controller.candidatoChangeset.get('area'));
      },

      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});