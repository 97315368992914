define("ods-app/controllers/candidato/record/informacion", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentDate: null,
    currentDateTitle: Ember.computed('currentDate', function () {
      if (!this.get('currentDate')) {
        return;
      }

      let currentDate = (0, _moment.default)(this.get('currentDate'));
      return 'Debe ser anterior al ' + currentDate.format('DD/MM/YYYY');
    }),
    actions: {
      validateDPI(changeset, propertyName, value) {
        if (value.length < 13) {
          return;
        }

        this.store.query('candidato', {
          filter: {
            uid: value
          }
        }).then(candidato => {
          changeset._deleteKey('_errors', propertyName);

          if (candidato.get('firstObject')) {
            changeset.addError(propertyName, ['DPI ya registrado en el sistema']);
            return;
          }
        });
        changeset.set(propertyName, value);
      },

      validatePasaporte(changeset, propertyName, value) {
        if (value.length < 10) {
          return;
        }

        this.store.query('candidato', {
          filter: {
            pasaporte: value
          }
        }).then(candidato => {
          changeset._deleteKey('_errors', propertyName);

          if (candidato.get('firstObject')) {
            changeset.addError(propertyName, ['Pasaporte ya registrado en el sistema']);
            return;
          }
        });
        changeset.set(propertyName, value);
      }

    }
  });

  _exports.default = _default;
});