define("ods-app/models/aplicacion-historial", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    aplicacion: belongsTo('aplicacion'),
    estatus: attr('string'),
    correlativoTerna: attr('number'),
    createdAt: attr('custom-date', {
      defaultValue() {
        return new Date();
      }

    }),
    createdBy: attr('string'),
    observaciones: attr('string'),
    createdAtFormatted: Ember.computed('createdAt', function () {
      const momentDate = (0, _moment.default)(this.createdAt);

      if (Ember.isNone(this.createdAt) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY hh:mm a');
    }),
    estatusToString: Ember.computed('estatus', function () {
      const status = this.constants.APPLICATION_STATUS.findBy('key', this.estatus);

      if (this.estatus === this.constants.APPLICATION_STATUS_EN_TERNA && this.get('correlativoTerna')) {
        return `En terna ${this.get('correlativoTerna')}`;
      }

      return status ? status.name : this.estatus;
    })
  });

  _exports.default = _default;
});