define("ods-app/controllers/requerimiento/record/entrevista", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/mixins/ods-app-file-saver", "ods-app/models/aplicacion", "ods-app/models/entrevista", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleIndex, _odsAppFileSaver, _aplicacion, _entrevista, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_odsAppFileSaver.default, {
    parentCtrl: Ember.inject.controller('requerimiento.record'),
    modelClass: _aplicacion.default,
    entrevistaModelClass: _entrevista.default,
    // editRoute: 'aplicacion.record',
    editRoute: 'requerimiento.record.entrevista.record',
    reload: true,
    currentUser: Ember.inject.service(),
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'requerimiento'
        },
        value: this.get('record.id') || -1
      }, {
        selectedFilter: {
          filterName: 'custom.estatusHistorico'
        },
        value: this.constants.APPLICATION_STATUS_EN_ENTREVISTA
      }];
    }),
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'id'
      }]);
    }),
    columns: Ember.computed(function () {
      let currentStatus = [this.constants.APPLICATION_STATUS_EN_ENTREVISTA, this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA, this.constants.APPLICATION_STATUS_DESISTIO_EN_ENTREVISTA];
      let actions = {
        edit: {
          title: 'Editar',
          buttonClass: 'btn-primary',
          icon: 'pencil-square-o',
          action: this.actions.edit.bind(this)
        }
      };

      if (!(0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        actions.crearEntrevista = {
          title: 'Nueva entrevista',
          buttonClass: 'btn-warning',
          icon: 'plus-square',
          action: this.actions.crearEntrevista.bind(this)
        };
      }

      if (!(0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA') && !(0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        actions.mandarAplicacionAPruebas = {
          title: 'Mandar a pruebas técnicas',
          buttonClass: 'btn-success',
          icon: 'arrow-left',
          action: this.actions.mandarAPruebasTecnicas.bind(this)
        };
      }

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        actions.pruebas = {
          title: 'Enviar a pruebas psicometricas',
          buttonClass: 'btn-success',
          icon: 'envelope',
          action: this.actions.mandarAPruebas.bind(this)
        };
        actions.pruebasTecnicas = {
          title: 'Enviar a pruebas técnicas',
          buttonClass: 'btn-secondary',
          icon: 'gears',
          action: this.actions.mandarAPruebasTecnicas.bind(this)
        };
      }

      actions.enviarATerna = {
        title: 'Enviar a terna activa',
        buttonClass: 'btn-success',
        icon: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA') ? 'user' : 'share',
        action: this.actions.asignarAplicacionATernaActiva.bind(this)
      };
      actions.desistir = {
        title: 'Desistir aplicación',
        buttonClass: 'btn-warning',
        icon: 'times',
        action: this.actions.desistirAplicacion.bind(this)
      };
      actions.rechazar = {
        title: 'Rechazar aplicación',
        buttonClass: 'btn-danger',
        icon: 'times',
        action: this.actions.rechazarAplicacion.bind(this)
      };
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: true,
        width: '100px',
        filtrable: true,
        filterName: 'codigo',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Candidato',
        valuePath: 'candidato.nombreCompleto',
        sortable: true,
        sortingPropertyName: 'candidato.primerNombre',
        width: '100px',
        filtrable: true,
        filterName: 'candidato.primerNombre',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Ocupación',
        valuePath: 'candidato.ocupacion',
        sortable: true,
        width: '100px',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Expectativa salarial',
        valuePath: 'expectativaSalarialFormatted',
        sortable: false,
        width: '100px',
        align: 'right',
        filtrable: true,
        filterName: 'expectativaSalarial',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: true,
        sortingPropertyName: 'estatus',
        width: '100px',
        filtrable: true,
        filterName: 'estatus',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: actions
      }]);
    }),
    entrevistasColumns: Ember.computed(function () {
      let entrevistasColumns = [{
        label: 'Candidato',
        valuePath: 'aplicacion.candidato.nombreCompleto',
        sortable: false,
        width: '100px'
      }, {
        label: 'Fecha',
        valuePath: 'fechaFormatted',
        sortable: false,
        width: '100px'
      }];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        entrevistasColumns.push({
          label: 'Estatus',
          valuePath: 'statusToString',
          sortable: false,
          width: '100px'
        });
      }

      entrevistasColumns.push({
        label: 'Tipo',
        valuePath: 'tipoToString',
        sortable: false,
        width: '75px'
      });

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        entrevistasColumns.push({
          label: 'Observaciones',
          valuePath: 'observaciones',
          sortable: false,
          width: '150px'
        });
      }

      entrevistasColumns.push({
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          descargar: {
            title: 'Descargar informe',
            buttonClass: 'btn-success',
            icon: 'download',
            action: this.actions.downloadPdf.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      });
      return Ember.A(entrevistasColumns);
    }),
    entrevistasModelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'aplicacion.requerimiento'
        },
        value: this.get('record.id') || -1
      }];
    }),

    reloadModel() {
      this.set('reload', true);
    },

    actions: {
      crearEntrevista(aplicacion) {
        const aplicacionId = aplicacion.get('id');
        const requerimientoId = aplicacion.get('requerimiento.id');
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error(this.messages.REQUERIMIENTO_DESHABILITADO);
          return;
        } else if (aplicacion.esRechazada) {
          this.toast.error(this.messages.APLICACION_RECHAZADA);
          return;
        } else if (aplicacion.esDesistida) {
          this.toast.error(this.messages.APLICACION_DESISTIDA);
          return;
        }

        this.transitionToRoute('requerimiento.record.entrevista.record', 'new', {
          queryParams: {
            'aplicacionId': aplicacionId,
            'requerimientoId': requerimientoId
          }
        });
      },

      mandarAPresentacion(aplicacion) {
        let controller = this;

        if (aplicacion.get('terna.content')) {
          this.toast.error(this.messages.APLICACION_EN_TERNA);
          return;
        } else if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error(this.messages.REQUERIMIENTO_DESHABILITADO);
          return;
        } else if (aplicacion.get('estatus') == this.constants.APPLICATION_STATUS_CONTRATADO) {
          this.toast.error(this.messages.APLICACION_CONTRATADA);
          return;
        } else if (aplicacion.get('estatus') == this.constants.APPLICATION_STATUS_COMPLETADO) {
          this.toast.error(this.messages.APLICACION_COMPLETADA);
          return;
        } else if (aplicacion.get('estatus') == this.constants.APPLICATION_STATUS_ENVIADO_REQUERIMIENTO) {
          this.toast.error(this.messages.APLICACION_PERTENECE_OTRO_REQUERIMIENTO);
          return;
        } else if (aplicacion.esRechazada) {
          this.toast.error(this.messages.APLICACION_RECHAZADA);
          return;
        } else if (aplicacion.esDesistida) {
          this.toast.error('Esta aplicación está desistida, no puede realizar ningún cambio.');
          return;
        }

        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_PRESENTACION);
        aplicacion.save().then(() => {
          this.reloadModel();
          this.toast.success('Aplicación enviada a presentación');
        });
      },

      asignarAplicacionATernaActiva(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error(this.messages.REQUERIMIENTO_DESHABILITADO);
          return;
        } else if (aplicacion.get('terna.content')) {
          this.toast.error(this.messages.APLICACION_EN_TERNA);
          return;
        } else if (controller.get('record.ternas.length') == 0) {
          this.toast.error('No hay ninguna terna asociada al requerimiento.');
          return;
        } else if (aplicacion.esRechazada) {
          this.toast.error(this.messages.APLICACION_RECHAZADA);
          return;
        } else if (aplicacion.esDesistida) {
          this.toast.error('Esta aplicación está desistida, no puede realizar ningún cambio.');
          return;
        }

        let ternaActiva = controller.get('record.ternas').filterBy('activo').get('firstObject');

        if (!ternaActiva) {
          this.toast.error('Todavía no se ha iniciado el proceso de reclutamiento.');
          return;
        }

        if (ternaActiva.get('aceptada')) {
          this.toast.error('la terna ya ha sido aceptada, no puede realizar ningún cambio.');
          return;
        }

        aplicacion.set('terna', ternaActiva);
        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_EN_TERNA);
        aplicacion.save().then(() => {
          this.reloadModel();
          this.toast.success('Aplicación agregada a terna activa');
        });
      },

      mandarAPruebas(aplicacion) {
        let controller = this;

        if (aplicacion.get('terna.content')) {
          this.toast.error(this.messages.APLICACION_EN_TERNA);
          return;
        }

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error(this.messages.REQUERIMIENTO_DESHABILITADO);
          return;
        }

        if (aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_CONTRATADO) {
          this.toast.error(this.messages.APLICACION_CONTRATADA);
          return;
        }

        if (aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_COMPLETADO) {
          this.toast.error(this.messages.APLICACION_COMPLETADA);
          return;
        }

        if (aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_ENVIADO_REQUERIMIENTO) {
          this.toast.error(this.messages.APLICACION_PERTENECE_OTRO_REQUERIMIENTO);
          return;
        }

        if (aplicacion.esRechazada) {
          this.toast.error(this.messages.APLICACION_RECHAZADA);
          return;
        }

        if (aplicacion.esDesistida) {
          this.toast.error('Esta aplicación está desistida, no puede realizar ningún cambio.');
          return;
        }

        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_PRUEBAS);
        aplicacion.save().then(() => {
          this.reloadModel();
          this.toast.success('Aplicación enviada a pruebas');
        });
      },

      mandarAPruebasTecnicas(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error(this.messages.REQUERIMIENTO_DESHABILITADO);
          return;
        } else if (aplicacion.esRechazada) {
          this.toast.error(this.messages.APLICACION_RECHAZADA);
          return;
        } else if (aplicacion.esDesistida) {
          this.toast.error(this.messages.APLICACION_DESISTIDA);
          return;
        } else if (aplicacion.get('terna.content')) {
          this.toast.error(this.messages.APLICACION_EN_TERNA);
          return;
        }

        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_PRUEBAS_TECNICAS);
        aplicacion.save().then(() => {
          this.reloadModel();
          this.toast.success('Aplicación enviada a pruebas técnicas');
        });
      },

      rechazarAplicacion(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error(this.messages.REQUERIMIENTO_DESHABILITADO);
          return;
        } else if (aplicacion.esRechazada) {
          this.toast.error(this.messages.APLICACION_RECHAZADA);
          return;
        } else if (aplicacion.esDesistida) {
          this.toast.error(this.messages.APLICACION_DESISTIDA);
          return;
        }

        this.send('openModal', 'popups/enviar-lista-negra', {
          title: 'Rechazar aplicación',
          buttonActions: [{
            title: 'Rechazar aplicación',
            methodName: 'noEnviarAListaNegra',
            type: 'warning'
          }, {
            title: 'Enviar candidato lista negra',
            methodName: 'enviarAListaNegra',
            type: 'danger'
          }],
          enviarAListaNegra: razonListaNegra => {
            if (!razonListaNegra) {
              this.toast.error('Campo de razón requerido');
              return;
            }

            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA);
            aplicacion.set('observacionesRechazo', razonListaNegra);
            aplicacion.save().then(() => aplicacion.get('candidato')).then(candidato => {
              candidato.set('listaNegra', true);
              candidato.set('razonListaNegra', razonListaNegra);
              candidato.save().then(() => {
                this.reloadModel();
                this.toast.success('Aplicación rechazada');
              });
            });
          },
          noEnviarAListaNegra: razonListaNegra => {
            if (!razonListaNegra) {
              this.toast.error('Campo de razón requerido');
              return;
            }

            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA);
            aplicacion.set('observacionesRechazo', razonListaNegra);
            aplicacion.save().then(() => {
              this.reloadModel();
              this.toast.success('Aplicación rechazada');
            });
          }
        });
      },

      desistirAplicacion(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error(this.messages.REQUERIMIENTO_DESHABILITADO);
          return;
        } else if (aplicacion.esRechazada) {
          this.toast.error(this.messages.APLICACION_RECHAZADA);
          return;
        } else if (aplicacion.esDesistida) {
          this.toast.error(this.messages.APLICACION_DESISTIDA);
          return;
        } else if (aplicacion.estatus == this.constants.APPLICATION_STATUS_CONFIRMADO_PENDIENTE) {
          this.toast.error(this.messages.APLICACION_CONFIRMADA_PENDIENTE_INGRESO);
          return;
        } else if (aplicacion.get('terna.content')) {
          this.toast.error(this.messages.APLICACION_EN_TERNA);
          return;
        }

        this.send('openModal', 'popups/desistir-aplicacion', {
          title: 'Desistir aplicación',
          buttonActions: [{
            title: 'Desistir aplicación',
            methodName: 'desistirAplicacion',
            type: 'warning'
          }],
          desistirAplicacion: observaciones => {
            if (!observaciones) {
              this.toast.error('Campo de razón requerido');
              return;
            }

            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_DESISTIO_EN_ENTREVISTA);
            aplicacion.set('observacionesDesistimiento', observaciones);
            aplicacion.save().then(() => {
              this.reloadModel();
              this.toast.success('Aplicación desistida');
            });
          }
        });
      },

      editarAplicacion(aplicacion) {
        let editRoute = 'aplicacion.record'; // If the item is new, transition with the whole object (wont trigger the
        // model hook), otherwise transition with its id (will trigger the model hook)

        if (Ember.isNone(aplicacion.get('id'))) {
          this.transitionToRoute(editRoute, aplicacion);
          return;
        }

        this.transitionToRoute(editRoute, aplicacion.get('id'));
      },

      downloadPdf(entrevista) {
        let aplicacionId = entrevista.get('aplicacion.id');
        return this.getFileFromServer(`informe-entrevista`, {
          aplicacion: aplicacionId
        });
      },

      marcarFaseEntrevistas() {
        this.changeset.validate().then(() => {
          if (this.changeset.get('isInvalid')) {
            this.toast.error('Corrija los errores del formulario antes de realizar esta acción.');
            return;
          }

          this.changeset.set('fase', 'ENTREVISTAS');
          this.parentCtrl.submit();
        });
      }

    }
  });

  _exports.default = _default;
});